import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (e, t) {
  exports = t(_react);
}("undefined" != typeof self ? self : exports, function (e) {
  return function (e) {
    var t = {};

    function r(n) {
      if (t[n]) return t[n].exports;
      var o = t[n] = {
        i: n,
        l: !1,
        exports: {}
      };
      return e[n].call(o.exports, o, o.exports, r), o.l = !0, o.exports;
    }

    return r.m = e, r.c = t, r.d = function (e, t, n) {
      r.o(e, t) || Object.defineProperty(e, t, {
        enumerable: !0,
        get: n
      });
    }, r.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, r.t = function (e, t) {
      if (1 & t && (e = r(e)), 8 & t) return e;
      if (4 & t && "object" == typeof e && e && e.__esModule) return e;
      var n = Object.create(null);
      if (r.r(n), Object.defineProperty(n, "default", {
        enumerable: !0,
        value: e
      }), 2 & t && "string" != typeof e) for (var o in e) r.d(n, o, function (t) {
        return e[t];
      }.bind(null, o));
      return n;
    }, r.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return r.d(t, "a", t), t;
    }, r.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, r.p = "", r(r.s = 3);
  }([function (t, r) {
    t.exports = e;
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.Validate = t.Time = void 0;
    var n = u(r(5)),
        o = u(r(6));

    function u(e) {
      return e && e.__esModule ? e : {
        default: e
      };
    }

    t.Time = n.default, t.Validate = o.default;
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.useInterval = void 0;

    var n = function (e) {
      return e && e.__esModule ? e : {
        default: e
      };
    }(r(7));

    t.useInterval = n.default;
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.useTime = t.useStopwatch = t.useTimer = void 0;

    var n = Object.assign || function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var r = arguments[t];

        for (var n in r) Object.prototype.hasOwnProperty.call(r, n) && (e[n] = r[n]);
      }

      return e;
    };

    t.default = function (e) {
      if ((0, o.useEffect)(function () {
        console.warn("react-timer-hook: default export useTimer is deprecated, use named exports { useTimer, useStopwatch, useTime } instead");
      }, []), e.expiryTimestamp) {
        var t = (0, u.default)(e);
        return n({}, t, {
          startTimer: t.start,
          stopTimer: t.pause,
          resetTimer: function () {}
        });
      }

      var r = (0, i.default)(e);
      return n({}, r, {
        startTimer: r.start,
        stopTimer: r.pause,
        resetTimer: r.reset
      });
    };

    var o = r(0),
        u = c(r(4)),
        i = c(r(8)),
        a = c(r(9));

    function c(e) {
      return e && e.__esModule ? e : {
        default: e
      };
    }

    t.useTimer = u.default, t.useStopwatch = i.default, t.useTime = a.default;
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });

    var n = Object.assign || function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var r = arguments[t];

        for (var n in r) Object.prototype.hasOwnProperty.call(r, n) && (e[n] = r[n]);
      }

      return e;
    },
        o = function () {
      return function (e, t) {
        if (Array.isArray(e)) return e;
        if (Symbol.iterator in Object(e)) return function (e, t) {
          var r = [],
              n = !0,
              o = !1,
              u = void 0;

          try {
            for (var i, a = e[Symbol.iterator](); !(n = (i = a.next()).done) && (r.push(i.value), !t || r.length !== t); n = !0);
          } catch (e) {
            o = !0, u = e;
          } finally {
            try {
              !n && a.return && a.return();
            } finally {
              if (o) throw u;
            }
          }

          return r;
        }(e, t);
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      };
    }();

    t.default = function (e) {
      var t = e.expiryTimestamp,
          r = e.onExpire,
          s = e.autoStart,
          l = void 0 === s || s,
          d = (0, u.useState)(t),
          m = o(d, 2),
          v = m[0],
          p = m[1],
          y = (0, u.useState)(i.Time.getSecondsFromExpiry(v)),
          T = o(y, 2),
          g = T[0],
          b = T[1],
          h = (0, u.useState)(l),
          S = o(h, 2),
          w = S[0],
          O = S[1],
          j = (0, u.useState)(l),
          x = o(j, 2),
          _ = x[0],
          M = x[1],
          P = (0, u.useState)(f(v)),
          F = o(P, 2),
          E = F[0],
          k = F[1];

      function I(e) {
        var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
        k(f(e)), M(t), O(t), p(e), b(i.Time.getSecondsFromExpiry(e));
      }

      function D() {
        var e = new Date();
        e.setMilliseconds(e.getMilliseconds() + 1000 * g), I(e);
      }

      return (0, a.useInterval)(function () {
        E !== c && k(c);
        var e = i.Time.getSecondsFromExpiry(v);
        b(e), e <= 0 && (i.Validate.onExpire(r) && r(), O(!1), k(null));
      }, w ? E : null), n({}, i.Time.getTimeFromSeconds(g), {
        start: function () {
          _ ? (b(i.Time.getSecondsFromExpiry(v)), O(!0)) : D();
        },
        pause: function () {
          O(!1);
        },
        resume: D,
        restart: I,
        isRunning: w
      });
    };

    var u = r(0),
        i = r(1),
        a = r(2),
        c = 1000;

    function f(e) {
      if (!i.Validate.expiryTimestamp(e)) return null;
      var t = i.Time.getSecondsFromExpiry(e),
          r = Math.floor(1000 * (t - Math.floor(t)));
      return r > 0 ? r : c;
    }
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });

    var n = function () {
      function e(e, t) {
        for (var r = 0; r < t.length; r++) {
          var n = t[r];
          n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
        }
      }

      return function (t, r, n) {
        return r && e(t.prototype, r), n && e(t, n), t;
      };
    }();

    var o = function () {
      function e() {
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, e);
      }

      return n(e, null, [{
        key: "getTimeFromSeconds",
        value: function (e) {
          var t = Math.ceil(e),
              r = Math.floor(t / 86400),
              n = Math.floor(t % 86400 / 3600),
              o = Math.floor(t % 3600 / 60);
          return {
            seconds: Math.floor(t % 60),
            minutes: o,
            hours: n,
            days: r
          };
        }
      }, {
        key: "getSecondsFromExpiry",
        value: function (e, t) {
          var r = e - new Date().getTime();

          if (r > 0) {
            var n = r / 1000;
            return t ? Math.round(n) : n;
          }

          return 0;
        }
      }, {
        key: "getSecondsFromPrevTime",
        value: function (e, t) {
          var r = new Date().getTime() - e;

          if (r > 0) {
            var n = r / 1000;
            return t ? Math.round(n) : n;
          }

          return 0;
        }
      }, {
        key: "getSecondsFromTimeNow",
        value: function () {
          var e = new Date();
          return e.getTime() / 1000 - 60 * e.getTimezoneOffset();
        }
      }, {
        key: "getFormattedTimeFromSeconds",
        value: function (t, r) {
          var n = e.getTimeFromSeconds(t),
              o = n.seconds,
              u = n.minutes,
              i = n.hours,
              a = "",
              c = i;
          return "12-hour" === r && (a = i >= 12 ? "pm" : "am", c = i % 12), {
            seconds: o,
            minutes: u,
            hours: c,
            ampm: a
          };
        }
      }]), e;
    }();

    t.default = o;
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });

    var n = function () {
      function e(e, t) {
        for (var r = 0; r < t.length; r++) {
          var n = t[r];
          n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
        }
      }

      return function (t, r, n) {
        return r && e(t.prototype, r), n && e(t, n), t;
      };
    }();

    var o = function () {
      function e() {
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, e);
      }

      return n(e, null, [{
        key: "expiryTimestamp",
        value: function (e) {
          var t = new Date(e).getTime() > 0;
          return t || console.warn("react-timer-hook: { useTimer } Invalid expiryTimestamp settings", e), t;
        }
      }, {
        key: "onExpire",
        value: function (e) {
          var t = e && "function" == typeof e;
          return e && !t && console.warn("react-timer-hook: { useTimer } Invalid onExpire settings function", e), t;
        }
      }]), e;
    }();

    t.default = o;
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.default = function (e, t) {
      var r = (0, n.useRef)();
      (0, n.useEffect)(function () {
        r.current = e;
      }), (0, n.useEffect)(function () {
        if (!t) return function () {};
        var e = setInterval(function () {
          r.current && r.current();
        }, t);
        return function () {
          return clearInterval(e);
        };
      }, [t]);
    };
    var n = r(0);
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });

    var n = Object.assign || function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var r = arguments[t];

        for (var n in r) Object.prototype.hasOwnProperty.call(r, n) && (e[n] = r[n]);
      }

      return e;
    },
        o = function () {
      return function (e, t) {
        if (Array.isArray(e)) return e;
        if (Symbol.iterator in Object(e)) return function (e, t) {
          var r = [],
              n = !0,
              o = !1,
              u = void 0;

          try {
            for (var i, a = e[Symbol.iterator](); !(n = (i = a.next()).done) && (r.push(i.value), !t || r.length !== t); n = !0);
          } catch (e) {
            o = !0, u = e;
          } finally {
            try {
              !n && a.return && a.return();
            } finally {
              if (o) throw u;
            }
          }

          return r;
        }(e, t);
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      };
    }();

    t.default = function (e) {
      var t = e.autoStart,
          r = e.offsetTimestamp,
          c = (0, u.useState)(i.Time.getSecondsFromExpiry(r, !0) || 0),
          f = o(c, 2),
          s = f[0],
          l = f[1],
          d = (0, u.useState)(new Date()),
          m = o(d, 2),
          v = m[0],
          p = m[1],
          y = (0, u.useState)(s + i.Time.getSecondsFromPrevTime(v || 0, !0)),
          T = o(y, 2),
          g = T[0],
          b = T[1],
          h = (0, u.useState)(t),
          S = o(h, 2),
          w = S[0],
          O = S[1];
      return (0, a.useInterval)(function () {
        b(s + i.Time.getSecondsFromPrevTime(v, !0));
      }, w ? 1000 : null), n({}, i.Time.getTimeFromSeconds(g), {
        start: function () {
          var e = new Date();
          p(e), O(!0), b(s + i.Time.getSecondsFromPrevTime(e, !0));
        },
        pause: function () {
          l(g), O(!1);
        },
        reset: function () {
          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0,
              t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
              r = i.Time.getSecondsFromExpiry(e, !0) || 0,
              n = new Date();
          p(n), l(r), O(t), b(r + i.Time.getSecondsFromPrevTime(n, !0));
        },
        isRunning: w
      });
    };

    var u = r(0),
        i = r(1),
        a = r(2);
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });

    var n = Object.assign || function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var r = arguments[t];

        for (var n in r) Object.prototype.hasOwnProperty.call(r, n) && (e[n] = r[n]);
      }

      return e;
    },
        o = function () {
      return function (e, t) {
        if (Array.isArray(e)) return e;
        if (Symbol.iterator in Object(e)) return function (e, t) {
          var r = [],
              n = !0,
              o = !1,
              u = void 0;

          try {
            for (var i, a = e[Symbol.iterator](); !(n = (i = a.next()).done) && (r.push(i.value), !t || r.length !== t); n = !0);
          } catch (e) {
            o = !0, u = e;
          } finally {
            try {
              !n && a.return && a.return();
            } finally {
              if (o) throw u;
            }
          }

          return r;
        }(e, t);
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      };
    }();

    t.default = function (e) {
      var t = e.format,
          r = (0, u.useState)(i.Time.getSecondsFromTimeNow()),
          c = o(r, 2),
          f = c[0],
          s = c[1];
      return (0, a.useInterval)(function () {
        s(i.Time.getSecondsFromTimeNow());
      }, 1000), n({}, i.Time.getFormattedTimeFromSeconds(f, t));
    };

    var u = r(0),
        i = r(1),
        a = r(2);
  }]);
});
export default exports;